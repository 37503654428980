<template>
  <div>
    <div class="box-wrap-max pc-box" :class="headBg">
      <div class="box-wrap-min df df-ai-c df-jc-sb">
        <div class="left-wrap df df-ai-c">
          <img
            style="width: 10.5rem; height: 3.2rem"
            :src="
              require(`../../assets/index/logo0${
                headBg === 'initBg' ? 1 : 2
              }@2x.png`)
            "
            alt=""
          />
          <div class="line"></div>
          <img
            style="width: 8.5rem; height: 3.2rem"
            :src="
              require(`../../assets/index/yimuhaoliang0${
                headBg === 'initBg' ? 1 : 2
              }@2x.png`)
            "
            alt=""
          />
        </div>

        <div class="right-wrap df df-ai-c">
          <div class="right-item">首页</div>
          <div class="right-item">好粮食</div>
          <div class="right-item">服务介绍</div>
          <div class="right-item">产品体系</div>
          <div class="right-item">
            <a href="https://shop.gudaohe.cn/" target="_blank">在线下单</a>
          </div>
          <div class="right-item">招商加盟</div>
          <div class="right-item">关于我们</div>
          <div class="right-item-line"></div>
          <div class="right-item" style="margin-left: 0">
            <a
              href="https://shop.gudaohe.cn/admin/login?website_id=1"
              target="_blank"
              >商家版</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="box-wrap-max h5-box" style="width: 100%" :class="headBg">
      <div
        class="box-wrap-min df df-ai-c df-jc-sb"
        style="
          width: 100%;
          padding: 0 20px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        "
      >
        <div class="left-wrap df df-ai-c">
          <img
            style="width: 10.5rem; height: 3.2rem"
            :src="
              require(`../../assets/index/logo0${
                headBg === 'initBg' ? 1 : 2
              }@2x.png`)
            "
            alt=""
          />
          <div class="line"></div>
          <img
            style="width: 8.5rem; height: 3.2rem"
            :src="
              require(`../../assets/index/yimuhaoliang0${
                headBg === 'initBg' ? 1 : 2
              }@2x.png`)
            "
            alt=""
          />
        </div>
        <div class="right-wrap df df-ai-c">
          <img
            style="width: 20px; height: 20px"
            src="../../assets/index/top-icon-list2@2x.png"
            alt=""
            @click="menuDialogVisible = !menuDialogVisible"
          />
        </div>
      </div>
    </div>

    <div
      v-if="menuDialogVisible"
      class="menu-dialog-wrap"
      @click.self="menuDialogVisible = false"
    >
      <div class="menu-dialog">
        <div
          class="menu-item"
          :class="activeMenuIndex === index ? 'active' : ''"
          v-for="(item, index) in menuList"
          :key="index"
          @click="clickMenu(item, index)"
        >
          <div class="line-l"></div>
          <div class="menu-item__name">{{ item.name }}</div>
          <div class="line-r"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",

  data() {
    return {
      headBg: "initBg",
      menuList: [
        { name: "首页" },
        { name: "好粮食" },
        { name: "服务介绍" },
        { name: "产品体系" },
        { name: "在线下单" },
        { name: "招商加盟" },
        { name: "关于我们" },
      ],
      activeMenuIndex: 0,
      menuDialogVisible: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", () => {
      let a = document.documentElement.scrollTop;
      if (this.menuDialogVisible) {
        document.documentElement.scrollTop = a;
        return;
      }
      if (document.documentElement.scrollTop > 0) {
        this.headBg = "whiteBg";
      } else {
        this.headBg = "initBg";
      }
    });
  },

  methods: {
    clickMenu(item, index) {
      this.activeMenuIndex = index;
      this.menuDialogVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
.box-wrap-max {
  height: 8rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  //border-bottom: 1px solid #fff;
  transition: 0.3s linear;

  .box-wrap-min {
    height: 100%;
  }

  .left-wrap {
    .line {
      width: 1px;
      height: 2.8rem;
      background: #e6e6e6;
      margin: 0 2rem;
    }
  }

  .right-wrap {
    .right-item {
      margin-left: 5rem;

      &:hover {
        font-weight: bold;
      }
    }

    .right-item-line {
      width: 1px;
      height: 1.4rem;
      background: #fff;
      margin: 0 3rem;
    }
  }

  &.whiteBg {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 2px 15px 0px;

    .right-item {
      color: #383533;

      a {
        color: #383533;
      }
    }
  }
}

.menu-dialog-wrap {
  position: fixed;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(56, 53, 51, 0.8);
  z-index: 9999;
  transition: 0.3s linear;

  .menu-dialog {
    background: #fff;
    padding: 15px 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .menu-item {
      display: flex;
      height: 52px;
      align-items: center;
      justify-content: center;

      &.active {
        .menu-item__name {
          color: #fa8e00;
        }

        .line-l,
        .line-r {
          background: #fa8e00;
        }
      }

      .menu-item__name {
        width: 70px;
        text-align: center;
        margin: 0 36px;
        color: #383533;
        font-weight: bold;
      }

      .line-l,
      .line-r {
        width: 30px;
        height: 1px;
        background: #e6e6e6;
        border-radius: 1px;
      }
    }
  }
}
</style>
