<template>
  <div class="layout-wrap">
    <gdhHeader v-if="headVisible"></gdhHeader>

    <router-view></router-view>

    <gdhFooter></gdhFooter>

    <div class="chat_box pc-box-flex">
      <div class="chat-item chat-item-before" @click="openChat(1)">
        <div class="chat-icon"></div>
        <div class="chat-name">售前咨询</div>
      </div>
      <div class="chat-item" @click="openChat(2)">
        <div class="chat-icon chat-icon-after"></div>
        <div class="chat-name">售后服务</div>
      </div>
      <div class="chat-item chat-item-wx">
        <div class="chat-icon chat-icon-wx"></div>
        <div class="chat-name">微信咨询</div>

        <div class="ewm-wrap">
          <img src="../assets/index/hhrewm.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gdhHeader from "../components/gdh-header";
import gdhFooter from "../components/gdh-footer";

export default {
  name: "layout",
  components: {
    gdhHeader,
    gdhFooter,
  },
  data() {
    return {
      headVisible: true,
      noShowHeadArr: ["/ykb"],
    };
  },

  mounted() {
    this.headVisible = !this.noShowHeadArr.includes(this.$route.path);
  },
  watch: {
    $route: {
      handler() {
        this.headVisible = !this.noShowHeadArr.includes(this.$route.path);
      },
      deep: true,
    },
  },

  methods: {
    openChat(v) {
      let iHeight = 600,
        iWidth = 800;
      const iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
      const iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;

      switch (v) {
        case 1:
        case 2:
          window.open(
            "https://chat56.live800.com/live800/chatClient/chatbox.jsp?companyID=1552092&configID=581628&jid=2206739603&s=1",
            "",
            `height=${iHeight}, width=${iWidth}, top=${iTop}, left=${iLeft}, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, status=no`
          );
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.chat_box {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001;
  width: 80px;
  height: 330px;
  background: #ff9400;
  background: -o-linear-gradient(top, #ff9400, #ff8100);
  -webkit-box-shadow: 0 4px 20px 0 rgb(225 79 0 / 40%);
  box-shadow: 0 4px 20px 0 rgba(225, 79, 0, 0.4);
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .chat-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: 0.3s linear;

    &.chat-item-before {
      border-radius: 10px 0 0 0;
    }

    &:hover {
      background: #ff7f00;
    }

    .chat-icon {
      width: 36px;
      height: 36px;
      background-image: url(../assets/index/chat_icon.png);
      background-position: 0 0;
      background-repeat: no-repeat;

      &.chat-icon-after {
        background-position: -92px 0;
      }

      &.chat-icon-wx {
        background-position: -46px 0;
      }
    }

    .chat-name {
      font-size: 13px;
      margin-top: 10px;
    }

    &.chat-item-wx {
      position: relative;
      border-radius: 0 0 0 10px;

      .ewm-wrap {
        transition: 0.3s linear;
        display: none;
        position: absolute;
        width: 185px;
        transform: translate(-150px, -50px);
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 1px 20px 0 rgba(62, 62, 62, 0.25);

        img {
          float: left;
        }
      }

      &:hover {
        .ewm-wrap {
          display: block;
        }
      }
    }
  }
}
</style>
