<template>
  <div class="box-wrap-max">
    <div class="footer-nav">
      <div class="box-wrap-min">
        <div class="footer-wrap">
          <div class="footer-l">
            <div class="footer-l-item">
              <div class="footer-l-item__title fz16">关于我们</div>
              <div class="footer-l-item__desc fz12">谷道合介绍</div>
              <div class="footer-l-item__desc fz12">媒体报道</div>
              <div class="footer-l-item__desc fz12">联系谷道合</div>
            </div>
            <div class="footer-l-item">
              <div class="footer-l-item__title fz16">意见反馈</div>
              <div class="footer-l-item__desc fz12">常见问题</div>
              <div class="footer-l-item__desc fz12">如何下单</div>
              <div class="footer-l-item__desc fz12">如何付费</div>
              <div class="footer-l-item__desc fz12">如何开具发票</div>
            </div>
            <div class="footer-l-item">
              <div class="footer-l-item__title fz16">网站导航</div>
              <div class="footer-l-item__desc fz12">好粮食</div>
              <div class="footer-l-item__desc fz12">服务介绍</div>
              <div class="footer-l-item__desc fz12">产品体系</div>
              <div class="footer-l-item__desc fz12">在线下单</div>
              <div class="footer-l-item__desc fz12">招商加盟</div>
            </div>
            <div class="footer-l-item pc-box" style="flex: 0 0 30rem">
              <div class="footer-l-item__title fz16">联系客服</div>
              <div class="footer-l-item__desc fz12">客服电话：400-101-0509</div>
              <div class="footer-l-item__desc fz12">
                电子邮箱：wggudaohe@126.com
              </div>
              <div class="footer-l-item__desc fz12">
                工作时间：早8:00 至 晚21:00
              </div>
              <div class="footer-l-item__desc fz12">
                联系地址：山东省滨州经济技术开发区长江四路渤海二十一路黄河三角洲云计算大数据产业基地335房间
              </div>
            </div>
          </div>

          <div class="footer-l-item h5-box" style="margin-top: 4rem">
            <div class="footer-l-item__title fz16">联系客服</div>
            <div class="footer-l-item__desc fz12">客服电话：400-101-0509</div>
            <div class="footer-l-item__desc fz12">
              电子邮箱：wggudaohe@126.com
            </div>
            <div class="footer-l-item__desc fz12">
              工作时间：早8:00 至 晚21:00
            </div>
            <div class="footer-l-item__desc fz12">
              联系地址：山东省滨州经济技术开发区长江四路渤海二十一路黄河三角洲云计算大数据产业基地335房间
            </div>
          </div>

          <div class="footer-r">
            <div>
              <div class="fz16 fz-desc-color">官方公众号</div>
              <img
                class="ewm"
                src="../../assets/index/img-gzgzh@2x.png"
                alt=""
              />
              <div class="fz14 fz-desc-color tac">关注公众号</div>
            </div>
            <div>
              <div class="fz16 fz-desc-color">官方小程序</div>
              <img
                class="ewm"
                src="../../assets/index/img-gzxcx@2x.png"
                alt=""
              />
              <div class="fz14 fz-desc-color tac">关注小程序</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="beian fz-title-color-active fz10 df df-ai-c df-jc-c pc-box-flex"
    >
      谷道合&nbsp;&nbsp;www.gudaohe.cn&nbsp;&nbsp;版权所有：山东尊客顺通食品科技有限公司@2021～2022&nbsp;&nbsp;&nbsp;鲁ICP备2022016793号
    </div>
    <div
      class="beian fz-title-color-active fz10 df df-ai-c df-jc-c h5-box-flex"
    >
      <p>
        谷道合&nbsp;&nbsp;www.gudaohe.cn&nbsp;&nbsp;版权所有：山东尊客顺通食品科技有限公司
      </p>
      <p>@2021～2022&nbsp;&nbsp;&nbsp;鲁ICP备2022016793号</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
};
</script>

<style scoped lang="less">
.footer-nav {
  background: #141414;

  .footer-wrap {
    display: flex;
    padding: 8rem 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .footer-l {
      flex: 1;
      display: flex;
      margin-right: 17rem;
    }

    .footer-l-item {
      flex: 1;

      .footer-l-item__title {
        margin-bottom: 3.6rem;
        opacity: 0.4;
      }

      .footer-l-item__desc {
        margin-top: 1.2rem;
        opacity: 0.4;
      }
    }

    .footer-r {
      flex: 0 0 30rem;
      display: flex;
      justify-content: space-between;

      .ewm {
        width: 10.6rem;
        height: 10.6rem;
        margin: 3.6rem 0 1.6rem;
        border-radius: 0.5rem;
        border: 2px solid rgba(0, 0, 0, 0);
        transition: 0.3s linear;

        &:hover {
          border: 2px solid #fa8e00;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding: 4rem 4rem 6rem;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      .footer-l {
        margin: 0;
      }

      .footer-r {
        flex: none;
        margin-top: 6rem;

        .fz16 {
          text-align: center;
          font-weight: bold;
        }

        img.ewm {
          margin: 22px 0 18px;
        }
      }
    }
  }
}

.beian {
  background: #000;
  height: 4rem;

  @media only screen and (max-width: 768px) {
    font-size: 8px;
    color: rgba(255, 255, 255, 0.4);
    background: #000;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 8px;
    }
  }
}
</style>
